.Home .lander {
    padding: 80px 0;
    text-align: center;
}

.Home .lander h1{
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

.Home .notes h4 {
    font-family:  "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Home .notes p {
    color: #666;
}